/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"sortiment"}>
        <SiteHeader />

        <Column className="css-40cz2n css-42e4bw --style2 --full pr--10" parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19002/7e16985657124e8c9336daa9cb2b761e__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--stretch" style={{"maxWidth":813}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--102" style={{"maxWidth":1276}} content={"<br><span style=\"font-weight: bold; color: rgb(247, 4, 4);\"><br><br>Sortiment<br><br><br></span><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--48" content={"<span style=\"font-weight: bold; color: rgb(246, 5, 5);\">Užitková&nbsp; &nbsp;keramika</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--43" content={"Hrníčky"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(13, 26, 219);\">Hrníčky vyrábíme s různými dekory. Jsou od velikosti 0,6dcl až po velký 6dcl. A co se týče tvarů jsou rovné nebo rozšířené</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_bri=115_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_bri=115_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_bri=115_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_bri=115_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19002/3c6ecfe6a1024c7db36e9665ae87e507_bri=115_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19002/c63c838699224415b03daff3b251e174_bri=115_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19002/c63c838699224415b03daff3b251e174_bri=115_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/c63c838699224415b03daff3b251e174_bri=115_s=660x_.jpg 660w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19002/966018c7582f4570b85b76f7f66ae1dc_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19002/966018c7582f4570b85b76f7f66ae1dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/966018c7582f4570b85b76f7f66ae1dc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/966018c7582f4570b85b76f7f66ae1dc_s=860x_.jpg 860w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19002/69f426143678470486459a0a63111ae4_e=30x106x711x400_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19002/69f426143678470486459a0a63111ae4_e=30x106x711x400_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/69f426143678470486459a0a63111ae4_e=30x106x711x400_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Mističky, misky, mísy a talíře"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(32, 32, 205);\">Z našeho výběru talířů si můžete vytvořit svůj jídelní servis. Základem je hluboký a plítký talíř a k němu můžete mít ještě misku na kompot, případně malou na zmrzlinu</span>"}>
              </Text>

              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"4k1mpyuknhg"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/7561fb41933141579b546f091a1c3744_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/7561fb41933141579b546f091a1c3744_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/7561fb41933141579b546f091a1c3744_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"font-weight: bold;\">Velká mísa malovaná uvnitř</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/60befd52297546da9d42ea6a1dca291a_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/60befd52297546da9d42ea6a1dca291a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/60befd52297546da9d42ea6a1dca291a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/60befd52297546da9d42ea6a1dca291a_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"font-weight: bold;\">Mísa velká</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/cccf3f3964cb4cd4844af325ea889f56_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/cccf3f3964cb4cd4844af325ea889f56_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/cccf3f3964cb4cd4844af325ea889f56_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"font-weight: bold;\">Velký talíř jídelní</span>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19002/10a83228b28b4a0eb3d7ff200f2077af_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19002/10a83228b28b4a0eb3d7ff200f2077af_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19002/10a83228b28b4a0eb3d7ff200f2077af_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19002/10a83228b28b4a0eb3d7ff200f2077af_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"font-weight: bold;\">Velký talíř jidelní</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"tb5f7b8b3p"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Dekorativní keramika"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(35, 41, 193);\">Z dekorativní keramiky nabízíme :<br>hodiny<br>obrázky<br>zvonečky<br>svícny<br>aromalampy</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"0n338mr7fier"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Vzory"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(24, 39, 215);\">Vzory na naší keramice jsou malované ručně technikou pod galzurou, Tím je zajištěna dostečná ochrana, aby zůstal stále tak vybarvený jak ho vidíte při Vašem výběru když si ho kupujete.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1lj8was pb--50 pt--50" name={"o-nas"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19002/e20b63b6273d4d1ca16bdd597e98a2ff_r=810__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"font-weight: bold; color: rgb(4, 4, 4);\">www.keramikarna.cz</span><br>"}>
              </Title>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box fs--36" style={{"maxWidth":495}} content={"<span style=\"font-weight: bold; color: rgb(249, 7, 7);\">Našim přáním je, <br>aby každý náš výrobek děla radost svému majiteli a aby z hrníčku od nás, chutnalo.</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\"><span style=\"font-weight: bold;\">Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a><br><br><br><br><br><br></span><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}